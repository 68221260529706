<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto h-full scroll-stable',
      },
    }"
  >
    <template #header>
      <div
        class="text-xl font-semibold leading-7 text-gray-700 dark:text-white flex items-center"
        data-test="menu-header"
      >
        Settings
        <UButton
          color="gray"
          variant="ghost"
          icon="heroicons:x-mark"
          class="ml-auto"
          @click="closeSettings"
        />
      </div>
    </template>
    <div>
      <h4 class="font-semibold mb-1">Board settings</h4>
      <p class="text-sm text-gray-500">
        Create unique settings for this board.
      </p>
      <div class="mt-4 space-y-3">
        <div
          v-for="settingItem in settingItems"
          :key="settingItem.name"
          class="flex items-center justify-between rounded-md border border-gray-200 px-4 py-3"
          :class="(settingItem.isAllowed ? 'cursor-pointer' : 'cursor-not-allowed opacity-50')"
          @click="settingItem.isAllowed && settingItem.click()"
        >
          <div class="flex items-center gap-3">
            <div
              class="p-1 rounded-md size-6 flex items-center justify-center"
              :class="settingItem.bgColor"
            >
              <component :is="settingItem.icon" />
            </div>
            <span class="text-sm font-medium">{{ settingItem.name }}</span>
          </div>
          <Icon size="16" name="heroicons:chevron-right-20-solid" />
        </div>
      </div>
    </div>
    <div v-if="can('dashboard.settings_packs.apply_remove_settings_pack')" class="mt-4">
      <div class="flex items-center gap-3">
        <p class="font-semibold">Setting packs</p>
      </div>
      <p class="text-gray-500 text-sm mt-1">
        Combine task types, fields, status, labels, automation together to
        quickly update multiple project
      </p>
      <div class="flex flex-col gap-y-3 mt-3">
        <SettingsPackItemCard
          v-for="pack in settingsPacks"
          :key="pack.id"
          :item="pack"
          @click="handlePreviewSettingsPack"
        />
      </div>
      <div class="mt-4 space-y-3">
        <div
          class="flex items-center justify-between rounded-md border boder-gray-200 px-4 py-3 cursor-pointer"
          @click="setSettingTab('settingsPacks')"
        >
          <div class="flex items-center gap-3">
            <div
              class="p-1 rounded-md size-6 flex items-center justify-center bg-gray-50"
            >
              <IconSettingPack />
            </div>
            <span class="text-sm font-medium">Add settings packs</span>
          </div>
          <Icon size="16" name="heroicons:chevron-right-20-solid" />
        </div>
      </div>
    </div>
  </UCard>
</template>

<script lang="ts" setup>
import { Icon } from '#components'
import TaskIcon from '#core/components/icon/Task.vue'
import StatusIcon from '#core/components/icon/Status.vue'
import type { SettingsPack } from '#settings-pack/types'

const { setSettingTab, closeSettings } = useBoardSettingsNavigator()

const { can } = useBoardAbility()
const route = useRoute()
const router = useRouter()
const { currentBoard, setCurrentSettingsPack } = useWorkspaceSharedState()

const filter = reactive({
  search: '',
})

const variables = computed(() =>
  extend(filter, {
    boardId: currentBoard.value.id,
  })
)

const { result } = useBoardListsSettingsPacksQuery(variables.value)

const settingsPacks = computed(() => result.value?.boardSettingsPacks?.settingsPack)

const isValidPermissions = (permissions: string[]) => permissions.some((perm) => can(perm))

const settingItems = computed(() => {
  return [
    {
      name: 'Task types & Custom fields',
      bgColor: 'bg-blue-50',
      icon: TaskIcon,
      isAllowed: isValidPermissions(['settings.settings_packs.manage_tasktypes_custom_fields']),
      click: () => setSettingTab('tasks'),
    },
    {
      name: 'Statuses',
      bgColor: 'bg-yellow-50',
      icon: StatusIcon,
      isAllowed: isValidPermissions(['settings.settings_packs.manage_statuses']),
      click: () => setSettingTab('statuses'),
    },
    {
      name: 'Labels',
      bgColor: 'bg-green-50',
      icon: h(Icon, {
        name: 'heroicons:tag-solid',
        size: '18',
        class: 'text-green-500',
      }),
      isAllowed: isValidPermissions(['settings.settings_packs.manage_labels']),
      click: () => setSettingTab('labels'),
    },
    {
      name: 'Automations',
      bgColor: 'bg-orange-50',
      icon: h(Icon, {
        name: 'heroicons:bolt-solid',
        size: '18',
        class: 'text-orange-500',
      }),
      isAllowed: isValidPermissions(['settings.settings_packs.manage_automations']),
      click: () => setSettingTab('automations'),
    },
  ]
})

const handlePreviewSettingsPack = (settingsPack: SettingsPack) => {
  setCurrentSettingsPack(settingsPack)
  navigateToUrl(settingsPack.id, 'settingsPackPreview')
}

const navigateToUrl = async (id: string, hash: string) => {
  await router.push({ path: route.path, query: { id } })
  setSettingTab(hash)
}
</script>
